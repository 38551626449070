import React from 'react';
import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import MinusIcon from '../icons/Minus';
import logo from './logoEyerena.png';

const sections = [
  {
    title: 'Partner und Info',
    links: [
      {
        title: 'DynamicEye',
        href: 'https://dynamic-eye.de/'
      },
      {
        title: 'Research',
        href: '/Research'
      },
      {
        title: 'Visualhygiene',
        href: '/Visualhygiene'
      }
    ]
  },
  {
    title: 'Unternehmen',
    links: [
      {
        title: 'Kontakt',
        href: '/Kontakt'
      }
    ]
  },
  {
    title: 'Angaben',
    links: [
      {
        title: 'Datenschutz',
        href: '/Datenschutz'
      },
      {
        title: 'Impressum',
        href: '/Impressum'
      },
      {
        title: 'Nutzungsbedingungen',
        href: '/AGB'
      }
    ]
  }
];

const Footer = (props) => (
  <Box
    sx={{
      backgroundColor: 'background.default',
      pb: 6,
      pt: {
        md: 15,
        xs: 6
      }
    }}
    {...props}
  >
    <Container maxWidth="lg">
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={3}
          sm={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            order: {
              md: 1,
              xs: 4
            }
          }}
          xs={12}
        >
          <img
            src={logo}
            alt="Logo eyerena"
            width={220}
            height={50}
          />
          <Typography
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="caption"
            component="span"
          >
            © 2021 eyerena
          </Typography>
        </Grid>
        {sections.map((section, index) => (
          <Grid
            item
            key={section.title}
            md={3}
            sm={4}
            sx={{
              order: {
                md: index + 2,
                xs: index + 1
              }
            }}
            xs={12}
          >
            <Typography
              color="textSecondary"
              variant="overline"
              component="span"
            >
              {section.title}
            </Typography>
            <List disablePadding>
              {section.links.map((link) => (
                <ListItem
                  disableGutters
                  key={link.title}
                  sx={{
                    pb: 0,
                    pt: 1
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      minWidth: 0,
                      mr: 0.5
                    }}
                  >
                    <MinusIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <Link
                        href={link.href}
                        color="textPrimary"
                        variant="subtitle2"
                        target={(link.title === 'DynamicEye') ? '_blank' : '_self'}
                      >
                        {link.title}
                      </Link>
                    )}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
    </Container>
  </Box>
);

export default Footer;
