import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));
const ProductDetails = Loadable(lazy(() => import('./pages/dashboard/ProductDetails')));
const ProductMyDetails = Loadable(lazy(() => import('./pages/dashboard/ProductMyDetails')));
const Impressum = Loadable(lazy(() => import('./pages/dashboard/Impressum')));
const Faq = Loadable(lazy(() => import('./pages/dashboard/Faq')));
const Leitfaden = Loadable(lazy(() => import('./pages/dashboard/Leitfaden')));
const Visualhygiene = Loadable(lazy(() => import('./pages/dashboard/Visualhygiene')));
const Kontakt = Loadable(lazy(() => import('./pages/dashboard/Kontakt')));
const ProductMyList = Loadable(lazy(() => import('./pages/dashboard/ProductMyList')));
const ProductCreateForm = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Other pages

const Home = Loadable(lazy(() => import('./pages/Home')));
const ImpressumLanding = Loadable(lazy(() => import('./pages/Impressum')));
const DatenschutzLanding = Loadable(lazy(() => import('./pages/Datenschutz')));
const AGBLanding = Loadable(lazy(() => import('./pages/AGB')));
const ResearchLanding = Loadable(lazy(() => import('./pages/Research')));
const VisualhygieneLanding = Loadable(lazy(() => import('./pages/Visualhygiene')));
const KontaktLanding = Loadable(lazy(() => import('./pages/Kontakt')));

// Game pages

const HCDecoding = Loadable(lazy(() => import('./pages/games/HCDecoding')));
const FindNumbers = Loadable(lazy(() => import('./pages/games/FindNumbersPage')));

// Test Pages
const TestsSimpleArrowReactionPage = Loadable(lazy(() => import('./pages/games/TestsSimpleArrowReactionPage')));
const TestsColorWordsReactionnPage = Loadable(lazy(() => import('./pages/games/TestsColorWordsReactionPage')));

// Student pages

const StudentList = Loadable(lazy(() => import('./pages/dashboard/StudentList')));
const StudentCreateForm = Loadable(lazy(() => import('./pages/dashboard/StudentCreate')));
const StudentDetails = Loadable(lazy(() => import('./pages/dashboard/StudentDetails')));

const routesFree = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'impressum',
        element: <Impressum />
      },
      {
        path: 'kontakt',
        element: <Kontakt />
      },
      {
        path: 'FAQ',
        element: <Faq />
      },
      {
        path: 'Visualhygiene',
        element: <Visualhygiene />
      },
      {
        path: 'Leitfaden',
        element: <Leitfaden />
      },
      {
        path: 'myproducts',
        element: <ProductMyList />
      },
      {
        path: 'createproduct',
        element: <ProductCreateForm />
      },
      {
        path: 'createstudent',
        element: <StudentCreateForm />
      },
      {
        path: 'hcdecoding',
        element: <HCDecoding />
      },
      {
        path: 'findnumbers',
        element: <FindNumbers />
      },
      {
        path: 'testssimplearrowreaction',
        element: <TestsSimpleArrowReactionPage />
      },
      {
        path: 'testscolorwords',
        element: <TestsColorWordsReactionnPage />
      },
      {
        path: 'myproducts',
        children: [
          {
            path: '',
            element: <ProductMyList />
          },
          {
            path: ':productId',
            element: <ProductMyDetails />
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            element: <ProductList />
          },
          {
            path: ':productId',
            element: <ProductDetails />
          }
        ]
      },
      {
        path: 'students',
        children: [
          {
            path: '',
            element: <StudentList />
          },
          {
            path: ':studentId',
            element: <StudentDetails />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'Impressum',
        element: <ImpressumLanding />
      },
      {
        path: 'Datenschutz',
        element: <DatenschutzLanding />
      },
      {
        path: 'AGB',
        element: <AGBLanding />
      },
      {
        path: 'Kontakt',
        element: <KontaktLanding />
      },
      {
        path: 'Research',
        element: <ResearchLanding />
      },
      {
        path: 'Visualhygiene',
        element: <VisualhygieneLanding />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routesFree;
