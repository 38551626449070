import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routesFree from './routesFree';
import routesTrainer from './routesTrainer';
import routesTrainerPro from './routesTrainerPro';
import routesAdmin from './routesAdmin';
import { createCustomTheme } from './theme';
import routesOpen from './routesOpen';

const App = () => {
  const { settings } = useSettings();
  const auth = useAuth();

  let content;
  if (auth.isAuthenticated && auth.user.groups.includes('Admin')) {
    content = useRoutes(routesAdmin);
  } else if (auth.isAuthenticated && auth.user.groups.includes('Trainer')) {
    content = useRoutes(routesTrainer);
  } else if (auth.isAuthenticated && auth.user.groups.includes('TrainerPro')) {
    content = useRoutes(routesTrainerPro);
  } else if (auth.isAuthenticated && auth.user.groups.includes('Open')) {
    content = useRoutes(routesOpen);
  } else {
    content = useRoutes(routesFree);
  }
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      <SettingsDrawer />
      {auth.isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  );
};

export default App;
