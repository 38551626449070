import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Divider, Toolbar } from '@material-ui/core';
import logo from './logoEyerena.png';
import logoEye from './logoEye.png';

const MainNavbar = () => (
  <AppBar
    elevation={0}
    sx={{
      backgroundColor: 'background.paper',
      color: 'text.secondary'
    }}
  >
    <Toolbar sx={{ minHeight: 64 }}>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'inline',
            lg: 'inline'
          }
        }}
      >
        <RouterLink to="/">
          <img
            src={logo}
            alt="Logo eyerena"
            width={189}
            height={47}
          />
        </RouterLink>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'inline',
            md: 'none',
            lg: 'none'
          }
        }}
      >
        <RouterLink to="/">
          <img
            src={logoEye}
            alt="Logo eyerena"
            width={44}
            height={47}
          />
        </RouterLink>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box>
        <Button
          color="primary"
          component="a"
          href="https://eyerena.com/authentication/register"
          size="big"
          target="_blank"
          variant="contained"
        >
          registrieren
        </Button>
      </Box>
      <Box ml={2}>
        <Button
          color="primary"
          component="a"
          href="https://eyerena.com/authentication/login"
          size="big"
          target="_blank"
          variant="contained"
        >
          anmelden
        </Button>
      </Box>
    </Toolbar>
    <Divider />
  </AppBar>
);

export default MainNavbar;
