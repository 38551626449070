import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));
const ProductAdminList = Loadable(lazy(() => import('./pages/dashboard/ProductAdminList')));
const ProductDetails = Loadable(lazy(() => import('./pages/dashboard/ProductDetails')));
const ProductAdminDetails = Loadable(lazy(() => import('./pages/dashboard/ProductAdminDetails')));
const ProductAdminCreateForm = Loadable(lazy(() => import('./pages/dashboard/ProductAdminCreate')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Other pages

const Home = Loadable(lazy(() => import('./pages/Home')));
const ImpressumLanding = Loadable(lazy(() => import('./pages/Impressum')));
const DatenschutzLanding = Loadable(lazy(() => import('./pages/Datenschutz')));
const AGBLanding = Loadable(lazy(() => import('./pages/AGB')));
const ResearchLanding = Loadable(lazy(() => import('./pages/Research')));
const VisualhygieneLanding = Loadable(lazy(() => import('./pages/Visualhygiene')));
const KontaktLanding = Loadable(lazy(() => import('./pages/Kontakt')));

const routesAdmin = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'createadminproduct',
        element: <ProductAdminCreateForm />
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            element: <ProductList />
          },
          {
            path: ':productId',
            element: <ProductDetails />
          }
        ]
      },
      {
        path: 'productsadmin',
        children: [
          {
            path: '',
            element: <ProductAdminList />
          },
          {
            path: ':productId',
            element: <ProductAdminDetails />
          }
        ]
      },
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'Impressum',
        element: <ImpressumLanding />
      },
      {
        path: 'Datenschutz',
        element: <DatenschutzLanding />
      },
      {
        path: 'AGB',
        element: <AGBLanding />
      },
      {
        path: 'Kontakt',
        element: <KontaktLanding />
      },
      {
        path: 'Research',
        element: <ResearchLanding />
      },
      {
        path: 'Visualhygiene',
        element: <VisualhygieneLanding />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routesAdmin;
